var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.routeName))]),_c('button',{staticClass:"navbar-toggler navbar-burger",attrs:{"type":"button","aria-expanded":_vm.$sidebar.showSidebar,"aria-label":"Toggle navigation"},on:{"click":_vm.toggleSidebar}},[_c('span',{staticClass:"navbar-toggler-bar"}),_c('span',{staticClass:"navbar-toggler-bar"}),_c('span',{staticClass:"navbar-toggler-bar"})]),_c('div',{staticClass:"collapse navbar-collapse"},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item",attrs:{"id":"popoverBtn"}},[(
              _vm.counsellor.role === 'Administrator' ||
              _vm.counsellor.role === 'Call Handler'
            )?_c('a',{class:[
              {
                btn: true,
                'btn-success': _vm.phoneBtnName === 'Available',
                'btn-warning': _vm.phoneBtnName === 'Away',
                'btn-danger': _vm.phoneBtnName === 'Offline',
              },
            ],attrs:{"data-toggle":"popover","data-placement":"top"}},[_c('i',{staticClass:"ti-headphone-alt"}),_vm._v(" "+_vm._s(_vm.phoneBtnName)+" ")]):_vm._e()]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":_vm.logoutUser}},[_c('i',{staticClass:"ti-power-off"}),_vm._v(" Logout ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }