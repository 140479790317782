import Cookies from "js-cookie";

const getDomain = () => window.location.hostname;

const COOKIES = {
  TOKEN: "_agatb_",
  USER: "_agatb_user_",
};

export const setAccessToken = (token) => {
  Cookies.set(COOKIES.TOKEN, token, {
    domain: getDomain(),
  });
};
export const setUserData = (data) => {
  Cookies.set(COOKIES.USER, data, {
    domain: getDomain(),
  });
};

export const getAccessToken = () => Cookies.get(COOKIES.TOKEN);

export const deleteAccessToken = () =>
  Cookies.remove(COOKIES.TOKEN, {
    domain: getDomain(),
  });
export const deleteUserData = () =>
  Cookies.remove(COOKIES.USER, {
    domain: getDomain(),
  });

export const isSignedIn = () => {
  try {
    const user = Cookies.get(COOKIES.USER);
    console.log("user token", user);
    return !!user;
  } catch (ex) {
    return false;
  }
};

export const getCounsellor = () => JSON.parse(Cookies.get(COOKIES.USER));

export const signOutCleanUp = () => {
  deleteAccessToken();
  deleteUserData();
};
