import {
  signUp,
  signIn,
  signOut,
  sendSignupInvite,
  changePassword,
  editCounsellorInfo,
  fetchCounsellorsData,
  confirmWorkSpaceByToken,
  fetchCounsellorManagersData,
  fetchReferralAgenciesData,
  fetchCounsellorsAllData,
} from "@/utils/api/auth";

import { setAccessToken, setUserData, signOutCleanUp } from "@/utils/auth";

const defaultState = {
  isSigningUp: false,
  isSigningIn: false,
  isSigningOut: false,

  signupInvite: false,
  isSignupInvite: false,

  changePassword: false,
  isChangePassword: false,

  workspaceSetup: false,
  isConfirmWorkspaceSetup: false,

  counsellorList: false,
  isFetchingCounsellorList: false,

  allCounsellorList: [],
  isFetchingAllCounsellorList: false,

  counsellorManagers: [],
  isFetchingCounsellorManagers: false,
  isDownloadingCounsellorManagers: false,

  referralAgencies: {},
  isFetchingReferralAgencies: false,
};

const mutations = {
  setIsSigningUp(state, status) {
    state.isSigningUp = status;
  },
  setIsSigningIn(state, status) {
    state.isSigningIn = status;
  },
  setIsSigningOut(state, status) {
    state.isSigningOut = status;
  },

  setWorkspaceSetup(state, status) {
    state.workspaceSetup = status;
  },
  setIsCompleteWorkspaceSetup(state, status) {
    state.workspaceSetup = status;
  },

  setSignupInvite(state, status) {
    state.signupInvite = status;
  },
  setIsSignupInvite(state, status) {
    state.isSignupInvite = status;
  },

  setChangePassword(state, status) {
    state.changePassword = status;
  },
  setIsChangePassword(state, status) {
    state.isChangePassword = status;
  },

  setFetchCounsellorList(state, data) {
    state.counsellorList = data;
  },
  setIsFetchingCounsellorList(state, status) {
    state.isFetchingCounsellorList = status;
  },

  setFetchAllCounsellorList(state, data) {
    state.allCounsellorList = data;
  },
  setIsFetchingAllCounsellorList(state, status) {
    state.isFetchingAllCounsellorList = status;
  },

  setIsFetchingCounsellorManagers(state, status) {
    state.isFetchingCounsellorManagers = status;
  },
  setCounsellorManagers(state, counsellorManagers) {
    state.counsellorManagers = counsellorManagers;
  },

  setReferralAgencies(state, referralAgencies) {
    state.referralAgencies = referralAgencies;
  },
  setIsFetchingReferralAgencies(state, status) {
    state.isFetchingReferralAgencies = status;
  },
};

const actions = {
  async signUp({ commit }, data) {
    try {
      commit("setIsSigningUp", true);
      const result = await signUp(data);
      setUserData(result);
      // const { accessToken } = result;

      commit("setIsSigningUp", false);
      // return accessToken;
    } catch (ex) {
      commit("setIsSigningUp", false);
      throw ex;
    }
  },

  async signIn({ commit }, data) {
    try {
      commit("setIsSigningIn", true);
      const result = await signIn(data);
      setUserData(result);
      commit("setIsSigningIn", false);
      // return accessToken;
    } catch (ex) {
      commit("setIsSigningIn", false);
      throw ex;
    }
  },

  async signOut({ commit }) {
    try {
      commit("setIsSigningOut", true);
      await signOut();
      signOutCleanUp();
      commit("setIsSigningOut", false);
    } catch (ex) {
      commit("setIsSigningOut", false);
      console.log(ex);
    }
  },

  async completeWorkspaceSetup({ commit }, data) {
    try {
      commit("setIsCompleteWorkspaceSetup", true);
      const result = await confirmWorkSpaceByToken(data);
      commit("setWorkspaceSetup", result);
      commit("setIsCompleteWorkspaceSetup", false);
    } catch (ex) {
      commit("setIsCompleteWorkspaceSetup", false);
      throw ex;
    }
  },

  async sendCounsellorInvite({ commit }, data) {
    try {
      commit("setIsSignupInvite", true);
      const result = await sendSignupInvite(data);
      commit("setSignupInvite", result);
      commit("setIsSignupInvite", false);
    } catch (ex) {
      commit("setIsSignupInvite", false);
      throw ex;
    }
  },

  async changePassword({ commit }, data) {
    try {
      commit("setIsChangePassword", true);
      const result = await changePassword(data);
      commit("setChangePassword", result);
      commit("setIsChangePassword", false);
    } catch (ex) {
      commit("setIsChangePassword", false);
      throw ex;
    }
  },

  async updateMemberInfo({ commit }, data) {
    try {
      commit("setIsSignupInvite", true);
      const result = await editCounsellorInfo(data);
      commit("setSignupInvite", result);
      commit("setIsSignupInvite", false);
    } catch (ex) {
      commit("setIsSignupInvite", false);
      throw ex;
    }
  },

  async fetchCounsellorList({ commit }, data) {
    try {
      commit("setIsFetchingCounsellorList", true);
      const result = await fetchCounsellorsData(data);
      commit("setFetchCounsellorList", result ? result.counsellors : []);
      commit("setIsFetchingCounsellorList", false);
    } catch (ex) {
      commit("setIsFetchingCounsellorList", false);
      throw ex;
    }
  },

  async fetchAllCounsellorList({ commit }, data) {
    try {
      commit("setIsFetchingAllCounsellorList", true);
      const result = await fetchCounsellorsAllData(data);
      commit("setFetchAllCounsellorList", result ? result.counsellors : []);
      commit("setIsFetchingAllCounsellorList", false);
    } catch (ex) {
      commit("setIsFetchingAllCounsellorList", false);
      throw ex;
    }
  },

  async updateCounsellorList({ commit }, data) {
    try {
      console.log({ data });
      commit("setFetchCounsellorList", data);
    } catch (ex) {
      throw ex;
    }
  },

  async fetchCounsellorManagers({ commit }, data) {
    try {
      commit("setIsFetchingCounsellorManagers", true);
      const results = await fetchCounsellorManagersData(data);
      commit("setCounsellorManagers", results);
      commit("setIsFetchingCounsellorManagers", false);
    } catch (ex) {
      commit("setIsFetchingCounsellorManagers", false);
      throw ex;
    }
  },

  async fetchReferralAgencies({ commit }, data) {
    try {
      commit("setIsFetchingReferralAgencies", true);
      const results = await fetchReferralAgenciesData(data);
      console.log({ fetchReferralAgencies: results });
      commit("setReferralAgencies", results.agenciesGroup);
      commit("setIsFetchingReferralAgencies", false);
    } catch (ex) {
      commit("setIsFetchingReferralAgencies", false);
      throw ex;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
