import invoke from "./invoke";

export const signUp = async ({ password, token }) => {
  try {
    const res = await invoke("POST", `/counsellor/signup/${token}`, {
      password,
    });
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const signIn = async (credentials) => {
  try {
    const res = await invoke("POST", "/counsellor/login", credentials);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const sendSignupInvite = async (data) => {
  try {
    const res = await invoke("POST", "/counsellor/create", data);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const confirmWorkSpaceByToken = async ({ token }) => {
  try {
    const res = await invoke("POST", `/workspace/add/${token}`);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCounsellorsData = async ({ workspaceId, page, count }) => {
  try {
    const res = await invoke(
      "GET",
      `/counsellor/team/${workspaceId}/${page}/${count}`
    );
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCounsellorsAllData = async (workspaceId) => {
  try {
    const res = await invoke("GET", `/counsellor/team/all/${workspaceId}`);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const editCounsellorInfo = async (data) => {
  try {
    const res = await invoke("POST", "/counsellor/edit", data);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const changePassword = async (data) => {
  try {
    const res = await invoke("POST", "/counsellor/password/change", data);
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const fetchCounsellorManagersData = async ({ workspaceId }) => {
  try {
    const res = await invoke(
      "GET",
      `/counsellor/managers/summary/${workspaceId}`
    );
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const fetchReferralAgenciesData = async ({
  workspaceId,
  lgaOfOccurrence,
}) => {
  try {
    const res = await invoke(
      "GET",
      `/counsellor/get/agencies/${workspaceId}/${lgaOfOccurrence}`
    );
    return res;
  } catch (ex) {
    throw ex;
  }
};

export const signOut = async () => {
  try {
    await invoke("POST", "/counsellor/logout");
  } catch (ex) {
    throw ex;
  }
};
