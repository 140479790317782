import { saveAs } from "file-saver";
import axios from "axios";
import { EventBus } from "..";
import { getAccessToken, setAccessToken } from "../auth";
import { APP_CONFIG } from "../";

const baseUrl = APP_CONFIG.API_URL;
const clientId = APP_CONFIG.CLIENT_ID;
const timeout = 10000;

export default async function invoke(
  method,
  path,
  data = undefined,
  options_ = {}
) {
  const options = Object.assign(
    {
      baseUrl,
      headers: {},
    },
    options_
  );

  const headers = {
    "X-Client-Id": `${clientId}`,
    Origin: window.location.origin,
  };

  const token = getAccessToken();
  if (token !== undefined) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (options.headers) {
    Object.entries(options.headers).forEach(([key, value]) => {
      headers[key] = value;
    });
  }

  const opts = {
    method,
    headers,
  };

  if (data) {
    if (opts.headers["Content-Type"] === undefined) {
      opts.headers["Content-Type"] = "application/json";
    }

    if (opts.headers["Content-Type"] === "multipart/form-data") {
      delete opts.headers["Content-Type"];
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(`${key}`, value);
      });
      opts.data = formData;
    } else {
      opts.data = JSON.stringify(data);
    }
  }

  let res;
  try {
    const url = `${baseUrl}${path}`
      .replace(/\+/, "%2B")
      .replace(/ /, "%20")
      .replace(/#/, "%23");

    const requestOptions = {
      url,
      method,
      data,
      headers,
      timeout,
    };

    res = await axios(requestOptions);
  } catch (ex) {
    let result = ex;
    if (ex.response) {
      res = ex.response;
      if (res.status === 401 && token !== undefined) {
        EventBus.$emit("expiredToken");
        return null;
      }

      if (res.status === 500) {
        EventBus.$emit("serverError");
      }
      result = ex.response.data;
    }
    throw result;
  }

  if (res.headers["set-authorization"]) {
    setAccessToken(res.headers["set-authorization"]);
  }

  return res.data;
}
